import React from "react"
import Styles from "./privacy-policy.module.css"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Page = () => (
  <Layout>
    <SEO title="Artificial Intelligence Services" />
    <div className={`${Styles.policy} lg:text-lg container mt-48 md:mt-64`}>
      <h1>PAWS &amp; TAILS TERMS AND CONDITIONS OF USE</h1>
      <h3>
        THE PAWS &amp; TAILS APPLICATION (“APPLICATION”) AND RELATED SERVICES
        ARE PROVIDED SUBJECT TO YOUR COMPLIANCE WITH THE TERMS AND CONDITIONS
        SET FORTH BELOW.  PLEASE READ THE FOLLOWING INFORMATION CAREFULLY.  BY
        INSTALLING THE APPLICATION YOU INDICATE YOUR AGREEMENT TO BE BOUND BY
        THE TERMS AND CONDITIONS SET FORTH BELOW.  IF YOU DO NOT AGREE TO BE
        BOUND BY THESE TERMS AND CONDITIONS, DO NOT DOWNLOAD, INSTALL OR USE
        THIS APPLICATION. Restrictions on Use
      </h3>
      <ul>
        <li>
          1. Restrictions on Use.  All pages within the Application and any
          material made available for download are the property of Treadway
          Technologies, Inc. and/or its affiliates. The Application is protected
          by federal and international copyright and trademark laws.  By using
          the Application, you agree for yourself or anyone acting on your
          behalf that no portion of the Application or its content may be
          printed, reprinted, published, republished, produced, reproduced,
          uploaded, downloaded, stored, reverse engineered, displayed in public,
          altered, modified, transmitted or distributed in any form without the
          express written permission of Treadway Technologies, Inc.. No
          permission is granted to you, or anyone acting on your behalf, to use
          the name of Treadway Technologies, Inc., its icons, logos or URLs. You
          shall keep intact any proprietary notices, including copyright
          notices, contained on any permissibly downloaded materials and shall
          comply with any applicable end user license agreements.  This
          Application is designed for your own personal use or the internal use
          of your business and Treadway Technologies, Inc. grants you a limited,
          non-exclusive, non-transferable and royalty free license to use the
          Application according to these terms.   Any rights not expressly
          granted by these Terms and Conditions or any applicable end user
          license agreements are reserved by Treadway Technologies, Inc..
          Trademark Notice
        </li>
        <li>
          2. Trademark Notice Treadway Technologies, Inc. are the logos,
          trademarks, and service marks of Treadway Technologies, Inc.]. All
          other trademarks, service marks and logos used in this Site are the
          trademarks, service marks or logos of their respective owners.
          Warranty Disclaimer
        </li>
        <li>
          3. Warranty Disclaimer. THIS APPLICATION, INCLUDING ANY CONTENT OR
          INFORMATION CONTAINED WITHIN IT OR ANY APPLICATION-RELATED SERVICE, IS
          PROVIDED “AS IS,” WITH ALL FAULTS, WITH NO REPRESENTATIONS OR
          WARRANTIES OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING, BUT
          NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR
          A PARTICULAR PURPOSE, AND NON INFRINGEMENT. YOU ASSUME TOTAL
          RESPONSIBILITY AND RISK FOR YOUR USE OF THIS APPLICATION,
          APPLICATION-RELATED SERVICES, AND/OR HYPERLINKED WEBSITES.  
        </li>
      </ul>

      <h3>
        TO THE MAXIMUM EXTENT PERMITTED BY LAW, Treadway Technologies, Inc., ITS
        AFFILIATES AND ITS SPONSORS ARE NEITHER RESPONSIBLE NOR LIABLE FOR ANY
        DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY,
        PUNITIVE, OR OTHER DAMAGES ARISING OUT OF OR RELATING IN ANY WAY TO THE
        APPLICATION, APPLICATION-RELATED SERVICES, CONTENT OR INFORMATION
        CONTAINED WITHIN THE APPLICATION, AND/OR ANY HYPERLINKED WEBSITE. YOUR
        SOLE REMEDY FOR DISSATISFACTION WITH THE APPLICATION,
        APPLICATION-RELATED SERVICES, AND/OR HYPERLINKED WEBSITES IS TO STOP
        USING THE APPLICATION AND/OR THOSE SERVICES.
      </h3>
      <h3>
        Although Treadway Technologies, Inc. attempts to ensure the integrity
        and accurateness of the Application, it makes no guarantees whatsoever
        as to the correctness or accuracy of the Application. It is possible
        that the Application could include inaccuracies or errors, and that
        unauthorized additions, deletions and alterations could be made to the
        Application by third parties. In the event that an inaccuracy arises,
        please inform Treadway Technologies, Inc. so that it can be corrected.
        Information contained on the Site may be changed or updated without
        notice. Release of Liability and Indemnification For Actions By Users.
      </h3>
      <ul>
        <li>
          4. Release of Liability and Indemnification for Action by Users. The
          Application is provided “as-is” without warranty of any kind, either
          express or implied.  Treadway Technologies, Inc., its affiliates
          and/or its sponsors are not responsible for the accuracy or
          completeness of any material viewed, uploaded to or downloaded from
          the Application or users of the Application. Use of the Application
          constitutes a waiver of any claim by any User against Treadway
          Technologies, Inc., its affiliates and/or its sponsors regarding the
          timeliness of any communication through the Application and an
          agreement to indemnify and hold harmless Treadway Technologies, Inc.,
          its affiliates and/or its sponsors against loss, including attorney's
          fees and costs, from any and every claim or demand of every kind and
          character, including claims for contribution which may be asserted by
          or against any user of the Application arising from the use of the
          Application. Confidential and Proprietary Information
        </li>
        <li>
          5. Confidential and Proprietary Information. Your privacy and security
          is very important to Treadway Technologies, Inc.. Treadway
          Technologies, Inc. does not want to receive confidential or
          proprietary information from you through the Application. By
          sending Treadway Technologies, Inc. any information or material, you
          grant Treadway Technologies, Inc. an unrestricted, irrevocable,
          world-wide, royalty free license to use, reproduce, display, perform,
          modify, transmit, and distribute those materials or information, and
          you also agree that Treadway Technologies, Inc. is free to use any
          ideas, concepts, know-how, or techniques that you send us for any
          purpose. Subject to the above, we do not sell, trade, or rent any
          information you provide to us. We do provide some of our services
          through contractual arrangements with affiliates, services providers,
          partners and other third parties.  Further information concerning the
          Application’s use of information can be found in the Application’s
          Privacy Policy.
        </li>
      </ul>
      <h2>Links or Pointers to Other Sites</h2>
      <ul>
        <li>
          6. Links or Pointers to Other Sites.  Treadway Technologies, Inc.
          makes no representations whatsoever about any other Website that you
          may access though this Application. When you access a non-Treadway
          Technologies, Inc. Website, please understand that it is independent
          from Treadway Technologies, Inc., and that Treadway Technologies, Inc.
          has no control over the content on that Website. In addition, a
          hyperlink to a non-Treadway Technologies, Inc. Website does not mean
          that Treadway Technologies, Inc. endorses or accepts any
          responsibility for the content, or the use, of the linked site. It is
          up to you to take precautions to ensure that whatever you select for
          your use or download utilizes adequate security measures and is free
          of such items as viruses, worms, Trojan horses, and other items of a
          destructive nature. Compatibility and System Requirements
        </li>
        <li>
          7. Compatibility and System Requirements. Chrome browser version 80+
          Use Subject to Internet Connection
        </li>
        <li>
          8. Use Subject to Internet Connection.  You acknowledge that your use
          of this Application requires an Internet connection and the
          availability of your access to this Application may be dependent on
          service provided by your Internet Service Provider which is outside
          the control of Treadway Technologies, Inc..  In utilizing an Internet
          or network connection to use this Application, you agree to abide by
          all terms and conditions of your Internet Service Provider.
          Termination of Application
        </li>
        <li>
          9. Termination of Application. Treadway Technologies, Inc. reserves
          the right to terminate this Application at any time upon notice to
          you.  Upon termination, any license or other rights granted to you
          shall automatically expire and any further use of the Application is
          expressly prohibited. Choice of Law and Venue
        </li>
        <li>
          10. Choice of Law and Venue.  These Terms and Conditions are entered
          into in the State of California and shall be governed by and construed
          in accordance with the laws of the State of California, exclusive of
          its choice of law rules. Each party to these Terms and Conditions
          submits to the exclusive jurisdiction of the state and federal courts
          sitting in the County of Orange in the State of California, and waives
          any jurisdictional, venue, or inconvenient forum objections to such
          courts. In any action to enforce these Terms and Conditions, the
          prevailing party will be entitled to costs and attorney’s fees. In the
          event that any of the Terms and Conditions are held by a court or
          other tribunal of competent jurisdiction to be unenforceable, those
          provisions shall be limited or eliminated to the minimum extent
          necessary so that these Terms and Conditions shall otherwise remain in
          full force and effect. Entire Agreement
        </li>
        <li>
          11. Entire Agreement. These Terms and Conditions constitute the entire
          agreement between Treadway Technologies, Inc. and you pertaining to
          the subject matter of this Agreement. In its sole discretion, Treadway
          Technologies, Inc.  may modify these Terms and Conditions by posting
          the revised version within this Application and you agree that each
          use of this Application by you is a new transaction governed by the
          terms of use listed within this Application at that time. No Unlawful
          or Prohibited Purpose
        </li>
        <li>
          12. No Unlawful or Prohibited Purpose. As a condition of your use of
          this Application , you warrant to Treadway Technologies, Inc. that you
          will not use the Application for any purpose that is unlawful,
          prohibited by these Terms and Conditions, and/or deemed inappropriate
          in the sole discretion of Treadway Technologies, Inc..  Actions which
          Treadway Technologies, Inc. considers inappropriate and grounds for
          termination of use of this Application include, but are not limited
          to, the following: • Using the Application to sell any goods or
          services that are unlawful in the location at which the content is
          posted or received or the goods or services delivered; • Using the
          Application to post, transmit or disseminate any content that is
          obscene, lewd, lascivious, pornographic, contains nudity or sexual
          acts, excessively violent, harassing, or otherwise objectionable;
          • Using the Application to post any content that advocates, promotes
          or otherwise encourages violence against any governments,
          organizations, groups or individuals or which provides instruction,
          information, or assistance in causing or carrying out such violence;
          • Using the Application to post any content that holds Treadway
          Technologies, Inc., its employees or shareholders up to public scorn
          or ridicule or would in any way damage or impair Treadway
          Technologies, Inc.’s reputation or goodwill; • Using the Application
          to post any content that violates any copyrights, patents, trademarks,
          trade secrets, or other intellectual property rights of others;
          • Failing to obtain all required permissions when using the
          Application to receive, upload, download, display, distribute, or
          execute programs or perform other works protected by intellectual
          property laws including copyright and patent laws; • Deleting or
          altering author attributes, copyright notices, or other copyright
          management information, unless expressly permitted in writing by the
          author or owner; • Using the Application in a tortious manner,
          including the posting of libelous, defamatory, scandalous,
          threatening, harassing or private information without the permission
          of the person(s) involved, or posting content that is likely to cause
          emotional distress; or • Introducing viruses, worms, Trojan horses, or
          other harmful code on the Internet.
        </li>
      </ul>
      <h3>Change in Terms</h3>
      <ul>
        <li>
          13.  Change in Terms. Treadway Technologies, Inc. may revise in its
          sole discretion these Terms and Conditions, without prior notice. Any
          such changes shall be posted by Treadway Technologies, Inc. within
          this Application.  You shall be responsible for periodically reviewing
          these Terms and Conditions to apprise yourself of any changes thereto.
          Your continued use of this Application constitutes your agreement to
          be bound by any and all such changes. Paws &amp; Tails Terms and
          Conditions Effective Date: 04/10/2020
        </li>
      </ul>
    </div>
  </Layout>
)

export default Page
